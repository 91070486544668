<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <span style="display: block;margin-top: 5px;">您确认审核通过吗？</span>
    <span style="display: block;margin-top: 5px;">如您确认审核通过，系统将针对三种类型的售后状态进行变更：</span>
    <span style="display: block;margin-top: 5px;">1. 仅退款 将变更成 待转账</span>
    <span style="display: block;margin-top: 5px;">2. 退货退款，将变更成 待客户退货</span>
    <span style="display: block;margin-top: 5px;">3. 换货，将变更成 待客户退货</span>
    <span style="display: block;margin-top: 30px;">tips：审核通过后一样可以再次拒绝 </span>

    <div slot="footer">
      <el-button @click="reSet">
        重置
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "confirmAfterSale",
  methods:{
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.close()
    },
    reSet(){
      this.close()
    }
  }
}
</script>

<style scoped>

</style>