<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="110px" label-position="left">
      <el-form-item label="备忘内容">
        <el-input style="width:50%" type="textarea" :row="5" v-model="orderMemo"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="reSet">
        重置
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "setMemo",
  data(){
    return {
      orderMemo:''
    }
  },
  methods:{
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.close()
    },
    reSet(){
      this.close()
    }
  }
}
</script>

<style scoped>

</style>