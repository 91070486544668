<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="110px" label-position="left">
      <el-form-item label="快递公司">
        <el-select v-model="logisticObj.company"></el-select>
      </el-form-item>
      <el-form-item label="物流单号">
        <el-input style="width:50%" v-model="logisticObj.order_sn"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="reSet">
        重置
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "setChangeLogistics",
  data(){
    return {
      logisticObj: {
        company: '',
        order_sn: '',
      }
    }
  },
  methods:{
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.close()
    },
    reSet(){
      this.close()
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 10px;
}
</style>